// Colors
$teal: #00a69c;

$color-alert: #F03131;
$color-alert-well: #a32020;
$color-success: #14b876;
$color-pink: #c95aa4;
$color-orange: #f06131;
$color-blue: #3981eb;
$color-dark-20: #58595b;

// Monochrome
$white: #FFFFFF;
$black: #000000;

// Spotlight palette
$color-grey-20: #F2F2F2;
$color-grey-80: #F8F8F8;
$color-grey-border: #B2B2B2;
$color-grey-900: #333;

// Alert
$alert-danger: $color-alert;
$alert-success: $color-success;

// Color link
$color-link: $color-blue;
$color-sidebar: $color-orange;
$color-mini-info: $color-dark-20;

// Sidebar
$bgcol-sidebar: $color-grey-80;

// Color border
$border-grey-light: $color-grey-20;
$border-grey-strong: $color-grey-border;

$panel-font-size: 13px;
$panel-line-height: 1.5;
$panel-line-to-show: 2;


// 
// Extends styles
// -------------------------

%hear__list-group {
  padding: 0;
  list-style: none;
}

%panel__login{
  padding: 0;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

%panel__forgot {
  height: auto;
  min-height: 200px;
  padding-top: 52px;
}

%form__title {
  font-size: 16px;
  color: $color-grey-900;
  text-align: center;
  margin-top: 0;
  margin-bottom: 4px;
}

%form__text {
  font-size: 12px;
  font-weight: 100;
  color: $color-dark-20;
  text-align: center;
  margin: 0 0 32px;
}

%success {
  font-size: 12px;
  color: $alert-success;
  text-align: center;
  margin-top: 0;
  margin-bottom: 12px;
}

%list__inline {
  display: inline-block;
}

.reset-border {
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-left: none !important;
}