.hear-modal {
  &.modal.fade .modal-dialog {
    top: 30%;
    opacity: 0;
    @include scale(0.1);
    @include transition(all 0.3s);
  }
  &.modal.fade.in .modal-dialog {
    opacity: 1;
    @include scale(1);
    @include translate3d(0, -30%, 0);
  }
}
.modal-backdrop {
  z-index: 98;
}
.modal {
  z-index: 100;
}
.dropdown-menu {
  top: 45% !important;
}
.hear-input--transparent {
  background-color: transparent;
  border: none;
}