.hear-checkbox {
  display: none;

  &:checked + label:before {
    content: '\ed6e';
    display: block;
    padding-left: 1px;
    font-family: 'icomoon';
    font-size: 10px;
    font-weight: 100;
    color: $white;
    background: $color-blue;
    border: 1px solid $color-blue;
    line-height: 13px;
  }

  + label {
    margin-left: 6px;
    font-size: 11px;
    color: $color-grey-800;
    cursor: pointer;
    
    &::before {
      content: '';
      position: absolute;
      top: 6px;
      left: 0;
      display: inline-block;
      width: 14px;
      height: 14px;
      background: $white;
      border-radius: 2px;
      border: 1px solid rgba(51, 51, 51, 0.3);
    }
  }
}