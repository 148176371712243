.sidebar {
  z-index: 90;
}
.hear-sidebar__container {
  width: 230px;
  height: 100vh;
  background: $white;
  color: $color-grey-700;
  @include box-shadow(none);

  .hear-navigation__main {
    padding-top: 24px;
    padding-bottom: 20%;
    background: $white;   
  }
}

.navigation {
  li {
  > .has-ul:after {
      content: "";
      right: 15px;
      width: 7px;
      height: 11px;
      margin-top: -5px;
      background: url("./assets/images/hear-arrow-right-16x.svg") no-repeat center center;
      background-size: contain;
    }
  }
  > li {
    margin-left: 16px;
    > .has-ul {
      padding-right: 0;
    }
    &.active {
      > a {
        background-color: $bgcol-sidebar !important;
        color: $color-sidebar !important;
        &:hover,
        &:focus {
          background-color: $bgcol-sidebar;
          color: $color-sidebar;
        }
      }
      > .has-ul:after {
        @include rotate(90deg);
      }
    }
    > .has-ul:after {
      content: "";
      width: 7px;
      height: 11px;
      margin-top: -5px;
      right: 14px;
      background: url("./assets/images/hear-arrow-right-16x.svg") no-repeat center center;
      background-size: contain;
      @include rotate(0deg);
      @include transition(-webkit-transform 0.2s ease-in-out);
    }

    ul {
      background-color: transparent;
      li.active > a {
        background-color: $bgcol-sidebar;
        color: $color-sidebar;      
        &:hover,
        &:active,
        &:focus {
          background-color: $bgcol-sidebar;
          color: $color-sidebar;      
        }
        .has-ul:nth-child(2):after {
          @include rotate(0deg);
        }
      } 
    }
  }

  > li > a {
    font-size: 13px;
    color: $color-grey-800;
    line-height: 26px;
    padding: 10px 8px 10px 0;    
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: $color-sidebar;
      background-color: $bgcol-sidebar;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    i.hear-icon {
      display: block;
      width: 25px;
      height: 25px;
      margin: 0 8px 0 8px;
      background: url("../../assets/images/ico-sidebar.svg") no-repeat left center;
    }
  }
  ul {
    > li > a {
      font-size: 13px;
      color: $color-grey-800;
      
      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        color: $color-sidebar;
        background-color: $bgcol-sidebar;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
  }
}