@import "../themes/_palette.scss";

body {
  font-family: $font-primary;
  font-size: 16px;
  color: $color-grey-900;
}
.hear-bg {
  position: relative;
  background-color: $color-grey-80;
}
.text__muted--top {
  font-size: 13px;
  margin-bottom: 0;
  color: $color-grey-900;
}
.text__based {
  font-size: 28px;
  line-height: 1.32;
  margin: 0 0 4px;
  @include font-weight(strong);
}
.text__state {
  font-size: 11px;
  @include font-weight(strong);

  &.text--success {
    color: $alert-success;
  }
  
  &.text--alert {
    color: $alert-danger;
  }

  &.text__state--info {
    color: $color-mini-info;
    font-style: italic;
    @include font-weight(light);
  }
}
.is-hide {
  display: none;
}
.hear-list-group {
  margin-bottom: 0;
}
ul,
ol {
  list-style: none;
}
.page-container {
  margin-top: 77px;
}
.panel--transparent {
  background-color: transparent;
}