// ------------------------------------------------------------------------------
//
//  # Main colors
//
//  List of the template main color palettes
//
//  Version: 1.0
//  Latest update: May 25, 2015
//
// ------------------------------------------------------------------------------


// Contextual colors
// ------------------------------


// Primary palette
$color-primary-50: #E3F2FD;
$color-primary-300: #64B5F6;
$color-primary-400: #42A5F5;
$color-primary-500: #2196F3;
$color-primary-600: #1E88E5;
$color-primary-700: #1976D2;
$color-primary-800: #1565C0;
$color-primary-900: #0D47A1;

// Danger palette
$color-danger-50: #FFEBEE;
$color-danger-300: #E57373;
$color-danger-400: #EF5350;
$color-danger-500: #F44336;
$color-danger-600: #E53935;
$color-danger-700: #D32F2F;
$color-danger-800: #C62828;
$color-danger-900: #B71C1C;

// Success palette
$color-success-50: #E8F5E9;
$color-success-300: #81C784;
$color-success-400: #66BB6A;
$color-success-500: #4CAF50;
$color-success-600: #43A047;
$color-success-700: #388E3C;
$color-success-800: #2E7D32;
$color-success-900: #1B5E20;

// Warning palette
$color-warning-50: #FBE9E7;
$color-warning-300: #FF8A65;
$color-warning-400: #FF7043;
$color-warning-500: #FF5722;
$color-warning-600: #F4511E;
$color-warning-700: #E64A19;
$color-warning-800: #D84315;
$color-warning-900: #BF360C;

// Info palette
$color-info-50: #E0F7FA;
$color-info-300: #4DD0E1;
$color-info-400: #26C6DA;
$color-info-500: #00BCD4;
$color-info-600: #00ACC1;
$color-info-700: #0097A7;
$color-info-800: #00838F;
$color-info-900: #006064;



// Additional colors
// ------------------------------

// Pink palette
$color-pink-50: #FCE4EC;
$color-pink-300: #F06292;
$color-pink-400: #EC407A;
$color-pink-500: #E91E63;
$color-pink-600: #D81B60;
$color-pink-700: #C2185B;
$color-pink-800: #AD1457;
$color-pink-900: #880E4F;

// Violet palette
$color-violet-50: #F3E5F5;
$color-violet-300: #BA68C8;
$color-violet-400: #AB47BC;
$color-violet-500: #9C27B0;
$color-violet-600: #8E24AA;
$color-violet-700: #7B1FA2;
$color-violet-800: #6A1B9A;
$color-violet-900: #4A148C;

// Purple palette
$color-purple-50: #EDE7F6;
$color-purple-300: #9575CD;
$color-purple-400: #7E57C2;
$color-purple-500: #673AB7;
$color-purple-600: #5E35B1;
$color-purple-700: #512DA8;
$color-purple-800: #4527A0;
$color-purple-900: #311B92;

// Indigo palette
$color-indigo-50: #E8EAF6;
$color-indigo-300: #7986CB;
$color-indigo-400: #5C6BC0;
$color-indigo-500: #3F51B5;
$color-indigo-600: #3949AB;
$color-indigo-700: #303F9F;
$color-indigo-800: #283593;
$color-indigo-900: #1A237E;

// Blue palette
$color-blue-50: #E1F5FE;
$color-blue-300: #4FC3F7;
$color-blue-400: #29B6F6;
$color-blue-500: #03A9F4;
$color-blue-600: #039BE5;
$color-blue-700: #0288D1;
$color-blue-800: #0277BD;
$color-blue-900: #01579B;

// Teal palette
$color-teal-50: #E0F2F1;
$color-teal-300: #4DB6AC;
$color-teal-400: #26A69A;
$color-teal-500: #009688;
$color-teal-600: #00897B;
$color-teal-700: #00796B;
$color-teal-800: #00695C;
$color-teal-900: #004D40;

// Green palette
$color-green-50: #F1F8E9;
$color-green-300: #AED581;
$color-green-400: #9CCC65;
$color-green-500: #8BC34A;
$color-green-600: #7CB342;
$color-green-700: #689F38;
$color-green-800: #558B2F;
$color-green-900: #33691E;

// Orange palette
$color-orange-50: #FFF3E0;
$color-orange-300: #FFB74D;
$color-orange-400: #FFA726;
$color-orange-500: #FF9800;
$color-orange-600: #FB8C00;
$color-orange-700: #F57C00;
$color-orange-800: #EF6C00;
$color-orange-900: #E65100;

// Brown palette
$color-brown-50: #EFEBE9;
$color-brown-300: #A1887F;
$color-brown-400: #8D6E63;
$color-brown-500: #795548;
$color-brown-600: #6D4C41;
$color-brown-700: #5D4037;
$color-brown-800: #4E342E;
$color-brown-900: #3E2723;

// Grey palette
$color-grey-50: #FAFAFA;
$color-grey-300: #999;
$color-grey-400: #888;
$color-grey-500: #777;
$color-grey-600: #666;
$color-grey-700: #555;
$color-grey-800: #444;
$color-grey-900: #333;

// Slate palette
$color-slate-50: #ECEFF1;
$color-slate-300: #90A4AE;
$color-slate-400: #78909C;
$color-slate-500: #607D8B;
$color-slate-600: #546E7A;
$color-slate-700: #455A64;
$color-slate-800: #37474F;
$color-slate-900: #263238;
