.selectboxit-default-arrow {
  &::after {
    content: '\63';
    font-family: $font-icon-hearsay;
    @include font-size(small);
  }
}
.selectboxit-open {
  background: $color-grey-80;
  .selectboxit-default-arrow {
    &::after {
      content: '\66';
      font-family: $font-icon-hearsay;
      @include font-size(small);
    }
  }
}
.selectboxit-container {
  .selectboxit {
    width: auto;
    padding-left: 24px;
    padding-right: 24px;
  }
  .selectboxit-options {
    overflow-y: hidden;
    padding: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.selectboxit-btn {
  height: 52px;
  border-bottom: none;
  .selectboxit-arrow-container {
    right: 24px;
  }
  &:focus {
    border-bottom: none;
    @include box-shadow(0 0px 8px 5px rgba(0,0,0,0.05));
  }
}
.selectboxit-container span, 
.selectboxit-container .selectboxit-options a {
  @include font-size(small);
  line-height: 2.6;
}
.selectboxit-list {
  margin: 3px 0 0;
  @include box-shadow(0 3px 8px 5px rgba(0,0,0,0.05));
  > .selectboxit-focus {
    > .selectboxit-option-anchor {
      text-decoration: none;
      padding-right: 40px;
      padding-left: 40px;
    }
  }  
  > .selectboxit-selected {
    > .selectboxit-option-anchor {
      background-color: transparent;
      color: $color-grey-900; 
    }
  }  
}
.selectboxit-option .selectboxit-option-anchor {
  padding: 8px 40px;
}
.hear-panel__select {
  &::before {
    content: '';
    position: absolute;
    right: 0 !important;
    left: 0 !important;
  }
}
.hear-panel__select--default {
  margin-bottom: 12px;
  background-color: $white;
  @include border-radius(6px);
}