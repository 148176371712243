.hear-button {
  display: block;
  padding: 9px 0;
  color: $white;
  line-height: 1.4;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 3px;
  @include transition(all .2s ease-in);

  &:hover,
  &:focus {
    text-decoration: none;
    color: $white;
    @include box-shadow(0 3px 5px 0px rgba(0,0,0,0.3));
  }
  &.btn--large{
    width: 100%;
  }
  &.btn--small{
    min-width: 127px;
    padding: 0 16px;
    @include font-size(small);
  }
  &.btn--mini {
    width: 100px;
    height: 31px;
    font-size: 11px;
  }
  &.btn--primary {
    background-color: $teal;
  }
  &.btn--secondary-var1 {
    display: block;
    color: $color-sidebar;
    background: transparent;
    border: 1px solid $color-sidebar;
  }
  &.btn--secondary-var2 {
    display: block;
    color: $color-dark-20;
    background: transparent;
    border: 1px solid $color-dark-20;
  }
  &.btn--transparent {
    position: relative;
    display: block;
    width: 100%;
    padding: 10px 10px 10px 40px;
    outline: none;
    border: none;
    border-radius: 0;
    background: transparent;
    color: $color-grey-800;
    text-align: left;

    &:hover,
    &:focus {
      background: $color-grey-20;
      @include box-shadow(none);
    }
    i {
      position: absolute;
      top: 11px;
      left: 16px;
    }
  }
}

a[role="button"] {
  &.hear-button {
    padding: 12px 0;
  }
}