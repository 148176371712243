.hear-navbar__container {
  width: 100%;
  margin: auto;
  padding: 15px 40px;
}

.hear-navbar {
  width: 100%;
  height: 77px;
  margin: 0;
  background-color: $teal;

  .navbar__group {
    display: block;
  }

  .user__detail {

    .hear-list-group {
      @extend %hear__list-group;

      .hear-list-group__item {
        font-size: 12px;
        text-align: right;
        color: $white;
        
        &:nth-child(2) {
          font-size: 11px;
        }
      }
    }    
    .user__photo {
      width: auto;
      height: 100%;
      min-height: 32px;
      overflow: hidden;
      margin: 5px 8px;
      @include border-radius(100px);
    }
    .user__option {
      li {
        a.dropdown-toggle {
          display: block;
          padding: 8px;
        }
        .hear-dropdown {
          top: 4.7em !important;
          left: -12.3em;
          min-width: 200px;
          padding: 10px 0;
          border: none;
          border-radius: 3px;
          @include box-shadow(0 2px 13px 1px rgba(0,0,0,0.1));
          .hear-list-group {
            li {
              > a {
                display: block;
                padding: 10px 22px;
                // margin-bottom: 5px;
                color: $color-grey-700;

                > i {
                  display: inline-block;
                  margin-right: 10px;
                  @include translate(0, 2px);
                }

                &:hover {
                  text-decoration: none;
                  background: $color-grey-80;
                }
              }
            }
          }
        }
      }
    }
  }
  .logo {
    margin: 0;
  }
}

#imageUpload {
  display: none;
}

#avatarUser {
  // cursor: pointer;
}

.user__photo-profile {
  width: 32px;
  height: 32px;
  color: $color-sidebar;
  background: $color-grey-20;
  text-align: center;
  line-height: 32px;
  @include font-weight(strong);
  @include font-size(small);
}
