.panel {
  &.hear--panel {
    border: none;
    @include box-shadow(none);
  }
}

.table {
  margin-bottom: 0;
  th,
  td {
    @include font-size(small);
  }
  .hear-table__header {
    height: 42px;
    background: $bgcol-sidebar;
    .hear-checkbox {
      + label:before {
        top: 16px;
        left: 16px;
      }
    }
  }
  .hear-table__body {
    > tr {
      background-color: $white;
      @include border-radius(3px);
    }
  }
}

.table > thead > tr > th {
  border-bottom: none;
}

.table>thead>tr>th, 
.table>thead>tr>td, 
.table>tbody>tr>th, 
.table>tbody>tr>td, 
.table>tfoot>tr>th, 
.table>tfoot>tr>td {
  padding: 14px;
  border-top: 0;
}

.table tr, 
.table th, 
.table td, 
.htContextMenu td, 
.sidebar-user-material-menu > a, 
.navbar-nav > 
.active > a:after, 
.ranges ul li {
  border-bottom: 6px solid $bgcol-sidebar;
}

.dataTable tbody > tr:first-child > td.select-checkbox {
  width: 45px;
}

.dataTable tbody > tr.selected, 
.dataTable tbody > tr > .selected {
  background-color: $color-grey-20;
}

.hear-list-group__item {
  &.list--inline {
    @extend %list__inline;
    width: 15px;
    padding: 3px;
    margin: 0 6px;
  }
}

.hear--cell-option {
  > li > a {
    display: block;
    color: $color-mini-info;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }
}

// .spot--cell-option.last--cell {
//   display: none;
//   &.is-active {
//     display: block;
//     @include transition(all .2s ease-in);
//   }
// }

.selectboxit-selected {
  background-color: $color-grey-80;
}

// tbody {
//   > tr[role="row"] {
//     cursor: pointer;
//   }
// }